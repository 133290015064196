<script lang="ts">
	import type { HTMLAttributes } from 'svelte/elements'

	import { cn } from '$lib/utils/shadcn'

	type $$Props = HTMLAttributes<HTMLDivElement>

	let className: $$Props['class'] = undefined
	export { className as class }
</script>

<div
	class={cn('flex flex-1 flex-col pb-5 pl-5 pr-5 xs:pb-6 xs:pl-6 xl:pr-6', className)}
	{...$$restProps}
>
	<slot />
</div>
