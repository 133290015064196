<script lang="ts">
	import type { HTMLAttributes } from 'svelte/elements'

	import { cn } from '$lib/utils/shadcn'

	type $$Props = HTMLAttributes<HTMLDivElement> &
		Partial<Pick<HTMLAnchorElement, 'href' | 'target'>>

	let className: $$Props['class'] = undefined
	export { className as class }
</script>

{#if $$restProps.href}
	<a
		class={cn(
			'flex flex-col rounded-lg border bg-card text-card-foreground shadow-sm ring-offset-background transition-all hover:scale-101 hover:border-foreground focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
			className
		)}
		href={$$restProps.href}
		{...$$restProps}
	>
		<slot />
	</a>
{:else}
	<div
		class={cn(
			'relative flex flex-col rounded-lg border bg-card text-card-foreground shadow-sm',
			className
		)}
		{...$$restProps}
	>
		<slot />
	</div>
{/if}
